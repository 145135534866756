<template>
  <div>
    {{value}}
  </div>
</template>
<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number]
    },
    column: Column
  }
}
</script>
